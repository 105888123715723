<template>
  <div class="flex-1 flex p-4 -m-4  overflow-y-auto scroll-bar">
    <tabs @select="selectTab" :selected="tabs.selected" :tabs="tabs.list">
       <template #content-feature>
        <sources />
      </template>
      <template #content-creditPack>
        <credit-pack />
      </template>
      <template #content-inventory>
        <inventory />
      </template>
    </tabs>
  </div>
</template>

<script>
import tabs from "@/components/dashboard-components/tabs";
import creditPack from "./credit-packs-list.vue";
import inventory from "./inventory.vue";
import sources from "@/pages/source/index.vue";

export default {
  name: "field-tab",
  components: {
    tabs,
    creditPack,
    inventory,
    sources,
  },
  props: {},
  data() {
    return {
      tabs: {
        selected: "feature",
        list: [
          {
            id: "feature",
            label: "Feature",
          },
          {
            id: "creditPack",
            label: "Credit Pack",
          },
          {
            id: "inventory",
            label: "Inventory",
          },
          
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.tabs.selected = this.$route?.query?.tab || "feature";
  },
  methods: {
    selectTab(id) {
      this.tabs.selected = id;
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: this.tabs.selected,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
