<template>
  <div class="flex flex-1 w-full">
    <common-detail-table
      :bredCrumbs="customBreadCrumbs"
      :columnsProps="columns"
      :data-fetching-url="dataFetchingUrl"
      data-fetching-key="credit_packs_inventory"
      show-row-link
      showInventoryStatus
    />
  </div>
</template>

<script>
import CommonDetailTable from "./common-detail-table.vue";

export default {
  name: "inventory",

  components: {
    CommonDetailTable,
  },

  data: () => {
    return {
      customBreadCrumbs: [{ name: "Admin" }, { name: "Inventory" }],
      columns: [
        {
          label: "Unique Id",
          field: "unique_id",
          sortable: true,
          config: {
            filter: true,
            type: "link",
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Pack Name",
          field: "credit_pack_name",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Credits",
          field: "total_credits",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Validity",
          field: "validity",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Tenant Name",
          field: "tenant_name",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Tenant Order Id",
          field: "tenant_order_id",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Order Date",
          field: "order_date",
          field_type: "date",
          sortable: true,
          config: {
            filter: true,
            // filter_type: "date",
            type: "showDate",
            alignCenter: true,
            // query_keys: {
            //   start: "order_date_from",
            //   end: "order_date_to",
            // },
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Client Name",
          field: "client_name",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Client order Id",
          field: "client_order_id",
          sortable: true,
          config: {
            filter: true,
            alignCenter: true,
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Sale Date",
          field: "sale_date",
          field_type: "date",
          sortable: true,
          config: {
            filter: true,
            // filter_type: "date",
            type: "showDate",
            // query_keys: {
            //   start: "sale_date_from",
            //   end: "sale_date_to",
            // },
            customClass: "flex justify-center items-center",
          },
          tdClass: "truncate max-w-xs",
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          config: {
            filter: true,
            type: "status_text",
          },
          tdClass: "truncate max-w-xs",
        },
      ],
      dataFetchingUrl: "/credit-pack-inventory",
    };
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
