<template>
  <div
    class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content"
  >
    <sub-header
      buttonText="Create Credit Pack"
      :handleClick="createCreditPack"
      :showBtn="true"
      :showDropDown="showDropDown"
      :dropDownOptions="dropDownOptions"
      :handleDropDownSelection="handleDropDownSelection"
      :showOnlyCustomCrumbs="true"
      :customCrumbLists="customBreadCrumbs"
    ></sub-header>

    <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
      <vue-good-table 
        :columns="columns" 
        :isLoading="loading" 
        :rows="filteredRowsData"
        :fixed-header="false"
        :pagination-options="{ enabled: true, mode: 'records', dropdownAllowAll: false }" 
        :sort-options="{enabled: true}"
      >
        <template #column-filter="{ column }">
          <dropdown
            ref="setFilterRef"
            reposition
            searchable
            class="filter-dropdown"
            placeholder="Select"
            v-if="column?.filterOptions && column?.filterOptions?.enabled"
            :options="getFilterOptions(column)"
            @search="searchFilter(column, $event)"
            @input="(value) => updateColumnFilters(column, value)"
            :value="filters[column.field]"
            :limit="1"
            maxWidth="100%"
            width="100px"
            minWidth="100%"
            :config="{ label: 'name', trackBy: 'id' }"
            :multiple="false"
            :taggable="false"
          >
          </dropdown>
        </template>
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'name'"
          :class="[props.row.system_default && 'default hover-text']" class="flex pl-3 gap-3">
          <router-link :to="{
            name: 'createCreditPack',
            params: {id: props.row.id}
          }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">{{
            props.row.name}}</router-link>
            </div>
            <div v-if="props.column.field == 'tenants'" class="flex justify-center cursor-pointer" @click="assignedTenants(props.row.tenants)">
                <div class="indicator">
                <span class="absolute bottom-1.5 left-5 rounded-full px-1.5 py-0.5 badge-primary text-xs ">{{props.row.tenants.length}}</span> 
                <font-awesome-icon class="w-6  mx-2" icon= "user"/>
                </div>
            </div>
            <div v-if="props.column.field == 'pack_credits'" class="flex justify-center">
                {{props.row.pack_credits}}
            </div>
            <div v-if="props.column.field == 'validity'" class="flex justify-center">
                {{props.row.validity}}
            </div>
            <div v-if="props.column.field == 'list_price'" class="flex justify-center">
                {{selectedCurrencySymbol+ '  '}}{{props.row.list_price ? props.row.list_price : "-"}}
            </div>
            <div v-if="props.column.field == 'active'" class="flex justify-center">
                <span v-if="props.row.active" class="text-green-400">Active</span>
                <span v-if="!props.row.active" class="text-red-400">Inactive</span>
            </div>
            <div v-if="props.column.field == 'actions'" class="flex justify-center cursor-pointer" @click="clonePack(props.row.id)">
              <font-awesome-icon icon='clone' />
            </div>
        </template>

        <template #loadingContent>
          <div class="w-full py-10 justify-center flex">
            <Loader />
          </div>
        </template>
      </vue-good-table>
      <button 
      v-if="Object.values(filters).length"
      class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
      Clear filters
      </button>
      <Modal
        ref="assigned-tenants"
        title="Assigned Tenants"
        :showFooter="false"
      >
        <template #content>
          <div class="grid gap-2">
            <span
              class="border-b text-bold"
              v-for="data in tenantsList"
              :key="data"
              >{{ data.tenant_name }}</span
            >
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import { getCurrenciesList, getCreditPacksList } from "./services";
import Loader from "@shared/loader";
import Modal from "@/components/modal-content";
import { FormatDateNew } from "@/plugins/functions";
import dropdown from "@shared/components/dropdown-base";
import { uuid } from "vue-uuid";

export default {
  name: "creditPacksList",
  components: {
    SubHeader,
    Loader,
    Modal,
    dropdown,
  },
  data: function () {
    return {
      customBreadCrumbs: [{ name: "Admin" }, { name: "Credit Pack" }],
      columns: [
        {
          label: "Pack Name",
          field: "name",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "name",
          },
        },
        {
          label: "Credits",
          field: "pack_credits",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "pack_credits",
          },
        },
        {
          label: "Pack Validity",
          field: "validity",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "validity",
          },
        },
        {
          label: "List Price",
          field: "list_price",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "list_price",
          },
        },
        {
          label: "Assigned Tenant",
          field: "tenants",
          filter_type: "tenant_users",
          sortable: true,
          filterOptions: {
            enabled: true, // enable filter for this column
            multi: true,
            isLoading: false,
            query_key: "tenants",
          },
        },
        {
          label: "Status",
          field: "active",
          sortable: true,
        },
        {
          label: "Actions",
          field: "actions",
          sortable: true,
        },
      ],
      rowsData: [],
      filteredRowsData: [],
      filters: {},
      masterCurrencies: [],
      dropDownOptions: {},
      loading: false,
      showDropDown: false,
      tenantsList: [],
      selectedCurrencySymbol: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.setMasterCurrency();
    await this.setCreditPacks(this.dropDownOptions.defaultValue.currency_code);
    this.filteredRowsData = this.rowsData;
    this.loading = false;
  },
  methods: {
    clonePack(pack_id) {
      this.$router.push({
        params: { id: pack_id },
        query: { clone: true },
        name: "createCreditPack",
      });
    },
    createCreditPack() {
      this.$router.push({ name: "createCreditPack" });
    },
    async setCreditPacks(currencyCode) {
      const { data } = await getCreditPacksList(currencyCode);
      this.rowsData = data.data;
      this.filteredRowsData = this.rowsData;
    },
    async setMasterCurrency() {
      const { data } = await getCurrenciesList();
      this.masterCurrencies = data.data.filter(
        (res) => res.master_currency == true
      );
      let baseCurrencyCheck = this.masterCurrencies.find(curr => curr?.base_currency == true);
      let baseCurrencyCheckIndex = this.masterCurrencies.indexOf(baseCurrencyCheck);
      if (baseCurrencyCheckIndex !== -1) {
        this.masterCurrencies.splice(baseCurrencyCheckIndex, 1);
        this.masterCurrencies.unshift(baseCurrencyCheck);
      }
      this.masterCurrencies.splice(0, baseCurrencyCheck)
      this.dropDownOptions.options = this.masterCurrencies;
      this.dropDownOptions.options.forEach((res) => {
        res.currency_name = res.currency_symbol + " " + res.currency_name;
      });
      this.dropDownOptions.label = "currency_name";
      this.dropDownOptions.trackBy = "currency_name";
      this.dropDownOptions.defaultValue = this.dropDownOptions.options[0];
      this.selectedCurrencySymbol =
        this.dropDownOptions.options[0].currency_symbol;
      this.showDropDown = true;
    },
    async handleDropDownSelection(selectedCurrency) {
      this.loading = true;
      await this.setCreditPacks(selectedCurrency.currency_code);
      this.selectedCurrencySymbol = selectedCurrency.currency_symbol;
      this.loading = false;
    },
    assignedTenants(tenants) {
      this.tenantsList = tenants;
      console.log(tenants)
      this.$refs["assigned-tenants"].showModal();
    },
    getFilterOptions(column){
      // get unique filter options array
      let filterData = []
      if (column?.filter_type == 'client_users' && this.rowsData?.length > 0) {
          this.rowsData.forEach((rowfilter) => {
              rowfilter?.clients?.map((rowData) => {
                  if (!filterData?.some?.(data => data?.name == rowData?.client_name)) {
                      filterData?.push(
                          {
                              id: rowData?.client_id,
                              name: rowData?.client_name
                          }
                      )
                  }
              })
          })
      } else if (column?.filter_type == 'tenant_users' && this.rowsData?.length > 0) {
          this.rowsData.forEach((rowfilter) => {
              rowfilter?.tenants?.map((rowData) => {
                  if (!filterData?.some?.(data => data?.name == rowData?.tenant_name)) {
                      filterData?.push(
                          {
                              id: rowData?.id,
                              name: rowData?.tenant_name
                          }
                      )
                  }
              })
          })
      } else {
          filterData = this.rowsData
          .filter((row) => row[column.field])
          .map((row) => (
              { 
                  id: row.field_type_id ?? uuid.v4(), 
                  label: row[column.field],
                  name: column?.filter_type == 'date' ? FormatDateNew(new Date (row[column.field])) : row[column?.field]
              }
          )
          )
          if (filterData && filterData?.length > 0) {
              const uniqueMap = new Map();
              filterData = filterData.forEach(item => {
                uniqueMap.set(item.name, item);
              });
              return Array.from(uniqueMap.values());
          }
      }
      return filterData;
    },
    updateColumnFilters(column, value){
        if (column?.filter_type == 'date') {
            this.filters[column.field]  =  value.label ? [value.label] : [] ;
        } else {
            this.filters[column.field]  =  value.name ? [value.name] : [] ;
        }
        let result = this.rowsData;
        for (let filter in this.filters) {
            if (this.filters[filter]?.length) {
                if (column?.filter_type == 'client_users') {
                    result = result?.filter((el) => el?.clients?.some(clnt => clnt?.client_name == value?.name))
                } else if (column?.filter_type == 'tenant_users') {
                    result = result?.filter((el) => el?.tenants?.some(tnt => tnt?.tenant_name == value?.name))
                } else {
                    result = result.filter((el) => this.filters[filter].includes(el[filter]));
                }
            }
        }
        this.filteredRowsData = result;
    },
    clearFilters() {
      this.filters = {};
      this.filteredRowsData = this.rowsData;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
