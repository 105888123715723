import axios from "@/axios"

export async function getCurrenciesList() {
    const url = "/currency"
    return await axios.get(url)
}

export async function getCreditPacksList(currencyCode) {
    const url = `/credit-pack?currency_code=${currencyCode}`
    return await axios.get(url)
}

