<template>
  <div
    class="field-table flex-1 flex flex-col max-h-full max-w-full min-h-full text-base-content"
  >
    <sub-header
      :showOnlyCustomCrumbs="true"
      :customCrumbLists="bredCrumbs"
      :backButton="showBackButton"
      :backRedirectionUrl="backRedirectionUrl"
    />
    <div
      class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar relative"
    >
      <table-large
        :columns="columnsProps"
        :rows="rows"
        :total-rows="totalRows"
        :isLoading="loading"
        :pagination-options="paginationOptions"
        :filters="serverParams"
        :sort-options="{
          enabled: true,
        }"
        sortingEnabled
        @sort-change="onSortChanged"
        :filterOptions="columnFilterOptions"
        :filterOptionsAsync="columnFilterOptionsAsync"
        @page-change="onPageChange($event)"
        @column-filter="onColumnFilter"
        @per-page-change="onPageChange($event, true)"
        @filters-clear="clearFilters"
        @search-filter="getSearchOptionsTick($event)"
        :config="{ sortingOption: { enabled: true } }"
      >
        <template #link="{ customData }" v-if="showRowLink">
          <span
            @click="onClick(customData.credit_pack_id)"
            class="text-primary cursor-pointer hover:text-primary-focus"
            >{{ customData.unique_id }}</span
          >
        </template>

        <template #svg_icon>
          <div class="flex justify-center items-center">
            <component :is="icon.querySvg" fill="currentColor"></component>
          </div>
        </template>

        <template #status_text="{ customData }">
          <div class="flex justify-center items-center">
            <span :class="getStatusColor(customData.status)">
              {{ customData.status }}</span
            >
          </div>
        </template>
      </table-large>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/SubHeader";
import TableLarge from "@shared/dashboard-components/table-large";
import queryIcon from "@/assets/icons/svg/credits-packs/query-icon.svg";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { format } from "date-fns";
import { FormatDateNew } from "@/plugins/functions";

import axios from "@/axios";

export default {
  name: "inventory",

  components: {
    SubHeader,
    TableLarge,
  },

  props: {
    bredCrumbs: {
      required: true,
      type: Array,
      default: () => [],
    },
    columnsProps: {
      required: true,
      type: Array,
      default: () => [],
    },
    showRowLink: {
      required: false,
      type: Boolean,
      default: false,
    },
    creditPackId: {
      required: false,
      type: String,
      default: "",
    },
    dataFetchingUrl: {
      required: false,
      type: String,
      default: "",
    },
    dataFetchingKey: {
      required: true,
      type: String,
      default: "",
    },
    showBackButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    backRedirectionUrl: {
      required: false,
      type: String,
      default: "",
    },
    showQueryIcon: {
      required: false,
      type: Boolean,
      default: false,
    },
    showCreditAllotedStatus: {
      required: false,
      type: Boolean,
      default: false,
    },
    showInventoryStatus: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      rows: [],
      totalRows: 0,
      paginationOptions: {
        enabled: true,
        mode: "remote",
        // perPage: 10
      },
      serverParams: null,
      sortParams: {
        sort_value: "asc",
      },
      columnFilterOptionsAsync: {},
      params: {
        page: 1,
        limit: 10,
      },
      loading: false,
      searchLoading: {},
      icon: {
        querySvg: queryIcon,
      },
    };
  },

  async mounted() {
    this.fetchInventoryData();
  },

  computed: {
    columnFilterOptions() {
      return this.getFilterOptions(this.columnOption, this.rows);
    },

    columnOption() {
      return this.columnsProps.map((el) => ({
        ...el,
        config: {
          ...el.config,
          isLoading: !!this.searchLoading[el.field] || false,
        },
      }));
    },
  },

  methods: {
    FormatDateNew,
    getStatusColor(status) {
      let color = "";
      if (status === "Sold Out" || status === "Completed") {
        color = "text-red-500";
      } else {
        color = "text-yellow-500";
      }
      return color;
    },
    formatDate(date) {
      if (date) {
        return format(new Date(date), "YYY-MM-dd");
      }
    },
    makeFilterParams() {
      let filterParams = {};
      if (this.serverParams) {
        Object.keys(this.serverParams).map((key) => {
          // if that key exist
          // add it to filter else we'll delete it
          // so it wont make empty strings in parameters
          filterParams[key] = this.serverParams[key]
            ? this.serverParams[key]["name"]
            : delete filterParams[key];

          if (this.serverParams[key]["name"] === undefined) {
            delete filterParams[key];
          }

          // if (key.includes("order_date") || key.includes("sale_date")) {
          //   filterParams[key] = this.formatDate(filterParams[key]);
          // }
        });
      }
      return filterParams;
    },
    async fetchInventoryData() {
      this.loading = true;
      try {
        let qParams = new URLSearchParams(this.params);
        const filterParams = this.makeFilterParams();
        if (filterParams) {
          qParams = new URLSearchParams({...this.params, ...filterParams});
        }
        let url = `${this.dataFetchingUrl}/?${qParams}`;
        if (this.creditPackId) {
          url = `${this.dataFetchingUrl}?credit_pack_id=${this.creditPackId}&${qParams}`;
        }
        const { data } = await axios.get(url, {
          params: {
            // ...filterParams,
            ...this.sortParams,
          },
        });
        this.rows = data[this.dataFetchingKey] ?? [];
        if (this.dataFetchingKey == 'credit_packs_inventory') {
          // this.rows = this.rows?.map(el => {
          //   return {
          //     ...el,
          //     order_date: FormatDateNew(new Date(el.order_date)),
          //     sale_date: FormatDateNew(new Date(el.sale_date)),
          //   }
          // })
        }
        this.totalRows = data?.pagination_info?.total_count || this.rows.length;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    searchFilter(column, event) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(column, event);
      }, delay);
    },
    onClick(id) {
      this.$router.push({
        name: "creditPackInventoryDetail",
        params: { id: id },
      });
    },
    async onSortChanged(data) {
      if (data && data.length && data[0].type !== "none") {
        data = data[0];
        this.sortParams = {
          sort_key: data.field,
          sort_value: data.type,
        };
        await this.fetchInventoryData();
      } else {
        this.sortParams = null;
        await this.fetchInventoryData();
      }
    },
    async getSearchOptions(payload) {
      if (!payload.value) this.columnFilterOptionsAsync = null;
      else {
        this.$set(this.searchLoading, payload.column.field, true);
        try {
          let qParams = new URLSearchParams(this.params);
          const sortParam = {
            sort_value: "asc",
          };
          const filterParams = this.makeFilterParams();
          const requestData = {
            ...filterParams,
            [payload.column.query_key || payload.column.field]: payload.value,
            ...sortParam,
          };
          let url = `${this.dataFetchingUrl}/?${qParams}`;
          if (this.creditPackId) {
            url = `${this.dataFetchingUrl}?credit_pack_id=${this.creditPackId}&${qParams}`;
          }
          const { data } = await axios.get(url, {
            params: requestData,
          });
          this.columnFilterOptionsAsync = this.getFilterOptions(
            this.columnsProps,
            data?.data
          );
        } catch (error) {
          console.error(error);
        }
        this.$set(this.searchLoading, payload.column.field, false);
      }
    },
    async onPageChange(data, resetPage = false) {
      if (resetPage) {
        this.params.page = 1;
      } else this.params.page = data.currentPage;
      this.params.limit = data.currentPerPage;
      await this.fetchInventoryData();
    },
    async clearFilters() {
      this.serverParams = null;
      await this.fetchInventoryData();
    },
    getSearchOptionsTick(payload) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      const delay = 500;
      this.searchTimeout = setTimeout(() => {
        this.getSearchOptions(payload);
      }, delay);
    },
    async onColumnFilter({ columnFilters }) {
      this.params.page = 1;
      this.serverParams = columnFilters ? { ...columnFilters } : null;
      await this.fetchInventoryData();
    },
    getFilterOptions(columns, rows) {
      return columns.reduce((result, curr) => {
        result[curr.field] = rows
          .filter((row) => row[curr.field])
          .map((row) => row[curr.field])
          .filter(onlyUnique)
          .map((el) => ({ id: uuid.v4(), name: el }));
        return result;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
</style>
